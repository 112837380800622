import React, { useState, useContext, useEffect } from "react";
import { LocalizedLink as Link } from "gatsby-theme-i18n";
import { Row, Col, Container, Form, Button } from "react-bootstrap";
import { UserAuthContext } from "../../context";
import { useLogin } from "../../mutations";
import produce from "immer";
import _ from "lodash";
import { useAlert } from "react-alert";
import { useTrans } from "../../hooks";
import BetterRestoLogo from "../BetterRestoLogo";

// ====================================================

const Login = () => {
  const alert = useAlert();
  const transMsg = useTrans();
  const {
    data: loginData,
    error: loginError,
    setData: setLoginVariable,
  } = useLogin();
  const { setUser } = useContext(UserAuthContext);

  const [formData, setFormData] = useState({
    rememberMe: false,
    email: "",
    password: "",
  });

  const onInputChange = ({ target: { type, name, value, checked } }) => {
    setFormData(
      produce(formData, (draft) => {
        draft[name] = type === "checkbox" ? checked : value;
      })
    );
  };

  const onSubmit = (e) => {
    e.preventDefault();
    setLoginVariable({
      variables: {
        email: formData.email,
        password: formData.password,
      },
    });
  };

  useEffect(() => {
    if (_.isEmpty(loginData)) return;
    const accessToken = loginData.login.jwt;
    if (_.isEmpty(accessToken)) return;

    setUser({
      email: formData.email,
      accessToken: accessToken,
      user: loginData.login.user.id,
    });
    console.log(accessToken);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loginData]);

  useEffect(() => {
    if (_.isEmpty(loginError)) return;
    console.log(JSON.stringify(loginError));
    const message =
      loginError.graphQLErrors?.[0]?.extensions?.exception?.data?.message?.[0]
        .messages?.[0].message;
    if (message) alert.error(message);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loginError]);
  return (
    <>
      <Container>
        <center>
          <BetterRestoLogo />
        </center>
        <br />
        <br />
        <Row>
          <Col md={9} lg={8} className="mx-auto pl-5 pr-5 bg-white p-5">
            <h3 className="login-heading mb-1"> {transMsg("welcomeBack")} </h3>
            <p className="login-heading mb-4 text-14">
              {transMsg("signWithEmailMessage")}
            </p>
            <Form onSubmit={onSubmit}>
              <div className="form-label-group">
                <Form.Control
                  type="email"
                  id="loginEmail"
                  placeholder={transMsg("emailAddress")}
                  name="email"
                  required
                  onChange={onInputChange}
                />
                <Form.Label htmlFor="loginEmail">
                  {" "}
                  {transMsg("email")}
                </Form.Label>
              </div>
              <div className="form-label-group">
                <Form.Control
                  type="password"
                  id="loginPassword"
                  placeholder={transMsg("password")}
                  onChange={onInputChange}
                  required
                  name="password"
                />
                <Form.Label htmlFor="loginPassword">
                  {transMsg("password")}
                </Form.Label>
              </div>
              <Form.Check
                className="mb-3"
                custom
                type="checkbox"
                id="login-custom-checkbox"
                label={transMsg("rememberPassword")}
                onChange={onInputChange}
                name="rememberMe"
              />
              <Button
                className="btn btn-lg btn-outline-primaryOFF btn-block btn-login text-uppercase font-weight-bold mb-2 round-btn theme-color-back"
                type="submit"
              >
                {transMsg("signIn")}
              </Button>
              <div className="text-center pt-3">
                <Link
                  className="font-weight-bold theme-color-text"
                  to="/forgot-password"
                >
                  {transMsg("forgotYourPassword")}
                </Link>
              </div>
              <div className="text-center pt-3">
                {transMsg("doNotHaveAccount")}{" "}
                <Link
                  className="font-weight-bold theme-color-text"
                  to="/register"
                >
                  {transMsg("signUp")}
                </Link>
              </div>
            </Form>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Login;
